import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PrivetRoute from "./Routes/PrivetRoute";
import PublicRoute from "./Routes/PublicRoute";
import VerifyRoute from "./Routes/VerifyRoute";
import TemporaryPrivetRoute from "./Routes/TemporaryPrivetRoute";

// actions
import { showSidebar } from "./redux/actions/helperAction";

// components

import { useRedux } from "./Hooks/useRedux";
import { useWindowSize } from "./Hooks/useWindowSize";
import BackdropLoading from "./layouts/BackdropLoading";

import DashboardLayout from "./components/Dashboard/DashboardLayout";
import LiveStream from "./components/Dashboard/MainContent/pages/live-stream";
import SpinLoader from "./layouts/SpinLoader";

// pages
const SignUp = lazy(() => import("./components/auth/SignUp"));
const LoginPage = lazy(() => import("./components/auth/LoginPage"));
const VerifyPage = lazy(() => import("./components/auth/VerifyPage"));
const ForgotPasswordPage = lazy(() =>
  import("./components/auth/ForgotPasswordPage")
);
const VerifyPageFP = lazy(() => import("./components/auth/VerifyPageFP"));
const CreateNewPassword = lazy(() =>
  import("./components/auth/CreateNewPassword")
);

const Dashboard = lazy(() =>
  import("./components/Dashboard/MainContent/pages/dashboard")
);
const Profile = lazy(() =>
  import("./components/Dashboard/MainContent/pages/profile")
);
// const HomePage = lazy(() => import("./components/HomePage"));
const Pages = lazy(() => import("./components/pages/Pages"));
const HomePage = lazy(() => import("./components/pages/HomePage"));
const HowDoseItWork = lazy(() => import("./components/pages/HowDoseItWork"));
const Features = lazy(() => import("./components/pages/Features"));
const Price = lazy(() => import("./components/pages/Price"));
const APIs = lazy(() => import("./components/pages/APIs"));
const UsageHistory = lazy(() =>
  import("./components/Dashboard/MainContent/pages/usage-history")
);
const ContactUs = lazy(() =>
  import("./components/Dashboard/MainContent/pages/contact-us")
);
const BuyCredit = lazy(() =>
  import("./components/Dashboard/MainContent/pages/buy-credits")
);

const Payment = lazy(() =>
  import("./components/Dashboard/MainContent/pages/payment")
);

// const SuspenseFallback = () => <div className="w-full h-full bg-gray-50"></div>;
const SuspenseFallback = () => (
  <div className="bg-gray-50 ">
    <div className="pointer-events-auto fixed top-0 right-0 left-0 bottom-0 bg-backdropBackground flex justify-center items-center z-[999999999999999999999999999999999999]"></div>
    <div className="pointer-events-auto fixed top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] z-[999999999999999999999999999999999999]">
      <SpinLoader />
    </div>
  </div>
);

const App = () => {
  const {
    data: { sidebar },
    dispatch,
  } = useRedux(["sidebar"]);

  const size = useWindowSize();
  useEffect(() => {
    // consoleHelper("size.width", window.innerWidth);
    if (window.innerWidth > 768) {
      dispatch(showSidebar(true));
    } else {
      dispatch(showSidebar(false));
    }
  }, []);

  useEffect(() => {
    // consoleHelper("size.width", window.innerWidth);
    if (size.width && size.width < 1020) {
      dispatch(showSidebar(false));
    }
    if (size.width && size.width > 1020) {
      dispatch(showSidebar(true));
    }
  }, [size.width]);

  return (
    <>
      <BackdropLoading />
      <ToastContainer />

      <Routes>
        {/* Normal Pages */}
        <Route
          exact
          path="/"
          element={
            <Suspense fallback={<SuspenseFallback />}>
              <Pages />
            </Suspense>
          }
        >
          <Route
            exact
            path=""
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <HomePage />
              </Suspense>
            }
          />
          <Route
            exact
            path="how-does-it-work"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <HowDoseItWork />
              </Suspense>
            }
          />
          <Route
            exact
            path="features"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <Features />
              </Suspense>
            }
          />
          <Route
            exact
            path="pricing"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <Price />
              </Suspense>
            }
          />
          <Route
            exact
            path="apis"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <APIs />
              </Suspense>
            }
          />
        </Route>

        {/* auth routes */}
        <Route
          exact
          path="/auth/signup"
          element={
            <PublicRoute
              _key="dat"
              redirectUrl="/b/dashboard"
              component={
                <Suspense fallback={<SuspenseFallback />}>
                  <SignUp />
                </Suspense>
              }
            />
          }
        />

        <Route
          exact
          path="/auth/verify"
          element={
            <VerifyRoute
              v_key="veracc"
              v_value="signup-verify"
              redirectUrl="/auth/signup"
              component={
                <Suspense fallback={<SuspenseFallback />}>
                  <VerifyPage />
                </Suspense>
              }
            />
          }
        />

        <Route
          exact
          path="/auth/forgot-password"
          element={
            <TemporaryPrivetRoute
              _key="forpas"
              value="forpas"
              redirectUrl="/"
              component={
                <Suspense fallback={<SuspenseFallback />}>
                  <ForgotPasswordPage />
                </Suspense>
              }
            />
          }
        />

        <Route
          exact
          path="/auth/verify-fp"
          element={
            <VerifyRoute
              v_key="verfp"
              v_value="fp-verify"
              redirectUrl="/auth/forgot-password"
              component={
                <Suspense fallback={<SuspenseFallback />}>
                  <VerifyPageFP />
                </Suspense>
              }
            />
          }
        />

        <Route
          exact
          path="/auth/create-password"
          element={
            <VerifyRoute
              v_key="crepas"
              v_value="crepas"
              redirectUrl="/"
              component={
                <Suspense fallback={<SuspenseFallback />}>
                  <CreateNewPassword />
                </Suspense>
              }
            />
          }
        />

        <Route
          exact
          path="/auth/login"
          element={
            <PublicRoute
              _key="dat"
              redirectUrl="/b/dashboard"
              component={
                <Suspense fallback={<SuspenseFallback />}>
                  <LoginPage />
                </Suspense>
              }
            />
          }
        />

        {/* Dashboard Routes */}
        <Route
          exact
          path="/b/dashboard"
          element={
            <PrivetRoute
              _key="dat"
              redirectUrl="/auth/login"
              component={<DashboardLayout />}
            />
          }
        >
          <Route
            exact
            path=""
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            exact
            path="profile"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            exact
            path="live-stream"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <LiveStream />
              </Suspense>
            }
          />
          <Route
            exact
            path="usage-history"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <UsageHistory />
              </Suspense>
            }
          />
          <Route
            exact
            path="contact-us"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <ContactUs />
              </Suspense>
            }
          />
          <Route
            exact
            path="buy-credit"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <BuyCredit />
              </Suspense>
            }
          />
          {/* <Route
            exact
            path="apis"
            element={
              <Suspense fallback={<div/>}>
                <APIs />
              </Suspense>
            }
          /> */}
        </Route>

        <Route
          exact
          path="/b/payment"
          element={
            <Suspense fallback={<SuspenseFallback />}>
              <Payment />
            </Suspense>
          }
        />

        <Route path="/*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </>
  );
};

export default App;
